import React from "react";
import AliceCarousel from "react-alice-carousel";
import { decode } from 'html-entities';

const handleDragStart = (e) => e.preventDefault();

class Gallery extends React.Component {
  render() {
    let array = [];
    this.props.items.forEach((item) => {
      item.name = decode(item.name);
      const thisItem = (
        <div className="text-center">
          <div className="associate text-light">
            { decodeURIComponent(item.name) }
          </div>
          { item.image_png_url &&
            item.url ?
            // image & link
            <a href={ item.url } title={ item.name } target="_blank" rel="noopener noreferrer">
              <img src={ item.image_png_url } onDragStart={ handleDragStart } role="presentation" alt={ item.name } />
            </a>
            :
            // img only
            <img src={ item.image_png_url } onDragStart={ handleDragStart } role="presentation" alt={ item.name } />
          }
        </div>
      );
      array.push(thisItem);
    });

    const responsive = {
      0: {
        items: 1,
      },
      512: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    };

    return (
      <AliceCarousel
        mouseTracking
        items={ array }
        infinite
        autoPlayStrategy="all"
        autoPlay
        autoPlayInterval="2000"
        responsive={ responsive }
        disableButtonsControls
      />
    );
  }
}

export default Gallery;
